import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Modal,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { URLS } from "../../Url"
import Select from "react-select"

const Banner = () => {
  const [modal_small, setmodal_small] = useState(false)
  const [banner, setbanner] = useState([])
  const [form, setform] = useState([])
  const [form1, setform1] = useState([])

  const [selectedOptions, setSelectedOptions] = useState([])

  const HealthScheme = selectedOptions => {
    setSelectedOptions(selectedOptions)
  }

  const [selectedOptions1, setSelectedOptions1] = useState([])

  const HealthScheme1 = selectedOptions1 => {
    setSelectedOptions1(selectedOptions1)
  }

  const [Actin, setActin] = useState([])

  useEffect(() => {
    GetUsers()
  }, [])

  const GetUsers = () => {
    var token = datas

    axios
      .post(
        URLS.GetAllUsers,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.users)
      })
  }

  const options = Actin.map(response => ({
    value: response._id,
    label: response.userCode + "/" + response.firstName + response.lastName,
  }))

  function tog_small() {
    setmodal_small(!modal_small)
  }

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }
  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  useEffect(() => {
    GetAllBanners()
    GetProductCategory()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = banner.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(banner.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const handleSubmit = e => {
    e.preventDefault()

    AddBanner()
  }

  const AddBanner = () => {
    var token = datas
    const dataArray = {
      staffId: form.staffId,
      members: selectedOptions,
      name: form.name,
    }
    axios
      .post(URLS.AddStaffGroups, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetAllBanners()
            clearForm()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const EditBanner = () => {
    var token = datas
    var formid = form1._id
    const dataArray = {
      staffId: form1.staffId,
      members: selectedOptions1,
      name: form1.name,
    }
    axios
      .put(URLS.EditStaffGroups + formid, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetAllBanners()
            setmodal_small(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const DeleteBanner = data => {
    var token = datas
    var remid = data._id
    axios
      .delete(URLS.DeleteStaffGroups + remid, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetAllBanners()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      DeleteBanner(data)
    }
  }

  const handleSubmit1 = e => {
    e.preventDefault()
    EditBanner()
  }

  const GetAllBanners = () => {
    var token = datas
    axios
      .post(
        URLS.GetStaffGroups,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setbanner(res.data.usergroups)
      })
  }

  const clearForm = () => {
    setform({
      staffId: "",
      name: "",
    })
    setSelectedOptions("")
  }

  const getpopup = data => {
    setform1(data)
    setSelectedOptions1(data.members)
    tog_small()
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const [Category, setCategory] = useState([])

  const GetProductCategory = () => {
    var token = datas
    axios
      .post(
        URLS.GetStaff,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setCategory(res.data.staff)
      })
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var Roles = data?.rolesAndPermission[0]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Od Cards Admin" breadcrumbItem="Plan Access" />

          <Row>
            {Roles?.StaffgroupAdd == true || Roles?.accessAll == true ? (
              <>
                <Col md={4}>
                  <Card>
                    <CardHeader className="bg-white">
                      <CardTitle>Add Plan Access</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Form
                        onSubmit={e => {
                          handleSubmit(e)
                        }}
                      >
                        <div className="mb-3">
                          <Label>Group Name</Label>
                          <span className="text-danger">*</span>
                          <Input
                            onChange={e => {
                              handleChange(e)
                            }}
                            name="name"
                            value={form.name}
                            required
                            type="text"
                            placeholder="Enter Group Name"
                          />
                        </div>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Staff <span className="text-danger">*</span>
                          </Label>
                          <select
                            value={form.staffId}
                            name="staffId"
                            onChange={e => {
                              handleChange(e)
                            }}
                            className="form-select"
                          >
                            <option value="">Select</option>
                            {Category.map((data, key) => {
                              return (
                                <option key={key} value={data._id}>
                                  {data.name}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                        <div className="mb-5">
                          <Label for="basicpill-firstname-input1">
                            Users <span className="text-danger">*</span>
                          </Label>
                          <Select
                            options={options}
                            placeholder="Users"
                            value={selectedOptions}
                            onChange={HealthScheme}
                            isSearchable={true}
                            isMulti
                          />
                        </div>
                        <div style={{ float: "right" }}>
                          <Button color="primary" type="submit">
                            Submit <i className="fas fa-check-circle"></i>
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </>
            ) : (
              ""
            )}

            <Col
              md={
                Roles?.StaffgroupAdd == true || Roles?.accessAll == true
                  ? 8
                  : 12
              }
            >
              <Card>
                <CardHeader className="bg-white">
                  <CardTitle>Plan Access List</CardTitle>
                </CardHeader>

                <CardBody>
                  <div>
                    <div className="table-responsive">
                      <Table className="table table-bordered mb-4 mt-4">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Group Name</th>
                            <th>Staff</th>
                            <th>Users</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {lists.map((data, key) => (
                            <tr key={key}>
                              <th>{(pageNumber - 1) * 5 + key + 6}</th>
                              <td>{data.name}</td>
                              <td>{data.staffName}</td>
                              <td>
                                {data.members.map((datas, i) => (
                                  <p key={i}>
                                    {i + 1}){datas.label}
                                  </p>
                                ))}
                              </td>
                              <td>
                                {Roles?.StaffgroupEdit == true ||
                                Roles?.accessAll == true ? (
                                  <>
                                    <Button
                                      onClick={() => {
                                        getpopup(data)
                                      }}
                                      className="mr-2"
                                      style={{
                                        padding: "6px",
                                        margin: "3px",
                                      }}
                                      color="success"
                                      outline
                                    >
                                      <i className="bx bx-edit "></i>
                                    </Button>{" "}
                                  </>
                                ) : (
                                  ""
                                )}
                                {Roles?.StaffgroupDelete == true ||
                                Roles?.accessAll == true ? (
                                  <>
                                    <Button
                                      onClick={() => {
                                        manageDelete(data)
                                      }}
                                      style={{
                                        padding: "6px",
                                        margin: "3px",
                                      }}
                                      color="danger"
                                      outline
                                    >
                                      <i className="bx bx-trash"></i>
                                    </Button>{" "}
                                  </>
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>

                      <div className="mt-3" style={{ float: "right" }}>
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          pageCount={pageCount}
                          onPageChange={changePage}
                          containerClassName={"pagination"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"disabled"}
                          activeClassName={"active"}
                          total={lists.length}
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal
          size="md"
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Edit Plan Access
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                handleSubmit1(e)
              }}
            >
              <div className="mb-3">
                <Label>Group Name</Label>
                <span className="text-danger">*</span>
                <Input
                  onChange={e => {
                    handleChange1(e)
                  }}
                  name="name"
                  value={form1.name}
                  required
                  type="text"
                  placeholder="Enter Group Name"
                />
              </div>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  Staff <span className="text-danger">*</span>
                </Label>
                <select
                  value={form1.staffId}
                  name="staffId"
                  onChange={e => {
                    handleChange1(e)
                  }}
                  className="form-select"
                >
                  <option value="">Select</option>
                  {Category.map((data, key) => {
                    return (
                      <option key={key} value={data._id}>
                        {data.name}
                      </option>
                    )
                  })}
                </select>
              </div>

              <div className="mb-4">
                <Label for="basicpill-firstname-input1">
                  Users <span className="text-danger">*</span>
                </Label>
                <Select
                  options={options}
                  placeholder="Users"
                  value={selectedOptions1}
                  onChange={HealthScheme1}
                  isSearchable={true}
                  isMulti
                />
              </div>

              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="primary" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default Banner
