import React, { useState, useEffect } from "react"
import { CardBody, Container, Row, Col, Card, Button } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import { URLS } from "../../Url"
import axios from "axios"

function RecruitView() {
  const history = useHistory()

  useEffect(() => {
    GetOneActins()
  }, [])

  const [Product, setProduct] = useState([])

  const [Products, setProducts] = useState([])

  const Actinid = sessionStorage.getItem("usersids")

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const GetOneActins = () => {
    const data = {
      userId: Actinid,
    }

    var token = datas
    axios
      .post(URLS.GetUserAddress, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setProduct(res.data.userDetailsWithAddresses.user)
        setProducts(res.data.userDetailsWithAddresses.addresses)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Od Cards Admin" breadcrumbItem="View Bookings" />
          <Row>
            <Col>
              <Button
                onClick={() => history.goBack()}
                className="mb-3  m-1 "
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i> Back
              </Button>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <h5 className="mb-4 text-primary">User Details : </h5>
              <Row>
                <Col lg={6}>
                  <ul className="verti-timeline list-unstyled">
                    <li className="event-list">
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <h6 className="font-size-14 ">User First Name:</h6>
                            <p className="text-muted">{Product.firstName}</p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="event-list">
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <h6 className="font-size-14 ">Phone Number:</h6>
                            <p className="text-muted">{Product.phone}</p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="event-list">
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <h6 className="font-size-14 ">User Gender:</h6>
                            <p className="text-muted">{Product.gender}</p>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Col>
                <Col lg={6}>
                  <ul className="verti-timeline list-unstyled">
                    <li className="event-list">
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <h6 className="font-size-14 ">User Last Number:</h6>
                            <p className="text-muted">{Product.lastName}</p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="event-list">
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <h6 className="font-size-14 ">User Email:</h6>
                            <p className="text-muted">{Product.email}</p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="event-list">
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <h6 className="font-size-14 ">User Status:</h6>
                            <p className="text-muted">{Product.status}</p>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Col>
              </Row>
            </CardBody>
          </Card>
          {Products.map((data, i) => (
            <Card key={i}>
              <CardBody>
              <h5 className="mb-4 text-primary">User Address ({i+1}) : </h5>
                <Row>
                  <Col lg={6} className="mt-3">
                    <ul className="verti-timeline list-unstyled">
                      <li className="event-list">
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <h6 className="font-size-14 ">Business Name:</h6>
                              <p className="text-muted">{data.gstNumber}</p>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="event-list">
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <h6 className="font-size-14 ">
                                Alternate Phone Number:
                              </h6>
                              <p className="text-muted">{data.gstNumber}</p>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="event-list">
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <h6 className="font-size-14 ">Address:</h6>
                              <p className="text-muted">{data.address}</p>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="event-list">
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <h6 className="font-size-14 ">Pin Code:</h6>
                              <p className="text-muted">{data.pincode}</p>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </Col>
                  <Col lg={6} className="mt-3">
                    <ul className="verti-timeline list-unstyled">
                      <li className="event-list">
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <h6 className="font-size-14 ">Gst Number:</h6>
                              <p className="text-muted">{data.gstNumber}</p>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="event-list">
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <h6 className="font-size-14 ">LandMark:</h6>
                              <p className="text-muted">{data.landmark}</p>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="event-list">
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <h6 className="font-size-14 ">Town / City:</h6>
                              <p className="text-muted">{data.city}</p>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          ))}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default RecruitView
