import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Table,
  Form,
  Label,
  Modal,
  Input,
} from "reactstrap"
import { ToastContainer, toast } from "react-toastify"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ReactPaginate from "react-paginate"
import { useHistory } from "react-router-dom"
import axios from "axios"
import { URLS } from "../../Url"

const Staff = () => {
  const history = useHistory()

  const [users, setusers] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const Get = () => {
    var token = datas

    axios
      .post(
        URLS.GetCancelBookings,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setusers(res.data.cancelledbookings)
      })
  }

  useEffect(() => {
    Get()
  }, [])

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = users.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(users.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const [modal_small1, setmodal_small1] = useState(false)

  const [form2, setform2] = useState([])

  const handleChange2 = e => {
    let myUser = { ...form2 }
    myUser[e.target.name] = e.target.value
    setform2(myUser)
  }

  function tog_small1() {
    setmodal_small1(!modal_small1)
  }

  const getpopup1 = data => {
    setform2(data)
    tog_small1()
  }

  const handleSubmit2 = e => {
    e.preventDefault()
    Editstate1()
  }

  const Editstate1 = () => {
    var token = datas
    var formid = form2._id

    const dataArray = {
      refundType: form2.refundType,
      cancelledTransactionId: form2.cancelledTransactionId,
      cancellationCharges: form2.cancellationCharges,
      cancellationStatus: "refunded",
    }

    axios
      .put(URLS.UpdateCancelBookings + formid, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            Get()
            toast(res.data.message)
            setmodal_small1(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const Actinid = data => {
    localStorage.setItem("bookingids", data._id)
    history.push("/ViewBookings")
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var Roles = data?.rolesAndPermission[0]

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Od Cards Admin"
            breadcrumbItem="Cancelled Bookings"
          />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <div className="col-md-12">
                      <div className="table-rep-plugin mt-4 table-responsive">
                        <Table hover bordered responsive>
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Booking Id</th>
                              <th>Booking Date</th>
                              <th>Booking Time</th>
                              <th>User Id</th>
                              <th>User Name</th>
                              <th>Product Name</th>
                              <th>Product Quantity</th>
                              <th>Product Price</th>
                              <th>Total Price</th>
                              <th>Status</th>
                              <th>Refund Type</th>
                              <th>Cancelled Transaction Id</th>
                              <th>Cancellation Charges</th>
                              <th>Refund Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists.map((data, key) => (
                              <tr key={key}>
                                <th scope="row">
                                  {(pageNumber - 1) * 5 + key + 6}
                                </th>
                                <td>{data.orderId}</td>
                                <td>{data.date}</td>
                                <td>{data.time}</td>
                                <td>{data.userCode}</td>
                                <td>{data.userName}</td>
                                <td>
                                  {data.items.map((datas, i) => (
                                    <>
                                      <p>
                                        {i + 1}){datas.productName}
                                      </p>
                                    </>
                                  ))}
                                </td>
                                <td>
                                  {data.items.map(datas => (
                                    <>
                                      <p>{datas.priceValue}</p>
                                    </>
                                  ))}
                                </td>
                                <td>
                                  {data.items.map(datas => (
                                    <>
                                      <p>{datas.price}</p>
                                    </>
                                  ))}
                                </td>
                                <td>{data.amount}</td>
                                <td>{data.status}</td>
                                <td>{data.refundType}</td>
                                <td>{data.cancelledTransactionId}</td>
                                <td>{data.cancellationCharges}</td>
                                <td>{data.cancellationStatus}</td>
                                <td>
                                  {data.cancellationStatus == "refunded" ? (
                                    <></>
                                  ) : (
                                    <>
                                      {Roles?.BokingsEdit == true ||
                                      Roles?.accessAll == true ? (
                                        <>
                                          <Button
                                            onClick={() => {
                                              getpopup1(data)
                                            }}
                                            size="sm"
                                            className="m-1"
                                            outline
                                            color="success"
                                          >
                                            <i
                                              style={{ fontSize: " 14px" }}
                                              className="bx bx-repeat"
                                            ></i>
                                          </Button>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  )}

                                  {Roles?.BokingsView == true ||
                                  Roles?.accessAll == true ? (
                                    <>
                                     <a
                                        href="#"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                      <Button
                                        className="mr-2"
                                        style={{
                                          padding: "6px",
                                          margin: "3px",
                                        }}
                                        onClick={() => {
                                          Actinid(data)
                                        }}
                                        color="info"
                                        outline
                                      >
                                        <i className="fas fa-eye"></i>
                                      </Button></a>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <Col sm="12">
                          <div
                            className="d-flex mt-3 mb-1"
                            style={{ float: "right" }}
                          >
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              pageCount={pageCount}
                              onPageChange={changePage}
                              containerClassName={"pagination"}
                              previousLinkClassName={"previousBttn"}
                              nextLinkClassName={"nextBttn"}
                              disabledClassName={"disabled"}
                              activeClassName={"active"}
                              total={lists.length}
                            />
                          </div>
                        </Col>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>

        <Modal
          size="md"
          isOpen={modal_small1}
          toggle={() => {
            tog_small1()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Status Update
            </h5>
            <button
              onClick={() => {
                setmodal_small1(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                handleSubmit2(e)
              }}
            >
              <div className="mb-3">
                <Label>Refund Type</Label>
                <span className="text-danger">*</span>
                <select
                  onChange={e => {
                    handleChange2(e)
                  }}
                  value={form2.refundType}
                  name="refundType"
                  className="form-select"
                  required
                >
                  <option value="">Select Type</option>
                  <option value="Phone Pay">Phone Pay</option>
                  <option value="Google Pay">Google Pay</option>
                  <option value="Refund">Refund</option>
                  <option value="Net Banking">Net Banking</option>
                </select>
              </div>

              <div className="col-lg-12">
                <div>
                  <label>Cancelled TransactionId :</label>
                  <textarea
                    type="text"
                    placeholder="Enter Cancelled TransactionId"
                    name="cancelledTransactionId"
                    rows={1}
                    onChange={e => {
                      handleChange2(e)
                    }}
                    value={form2.cancelledTransactionId}
                    className="form-control mt-2"
                    required
                  />
                </div>
              </div>

              <div className="mb-3 mt-3">
                <Label for="basicpill-firstname-input1">
                  Cancellation Charges<span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="basicpill-firstname-input1"
                  placeholder="Enter Cancellation Charges"
                  required
                  name="cancellationCharges"
                  value={form2.cancellationCharges}
                  onChange={e => {
                    handleChange2(e)
                  }}
                />
              </div>

              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small1(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="primary" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default Staff
