import React, { useState, useEffect } from "react"
import { Row, Col, Card, Input, Button, Label, Form } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { toast, ToastContainer } from "react-toastify"
import { useHistory } from "react-router-dom"
import Select from "react-select"
import { URLS } from "../../Url"
import axios from "axios"

const Addplan = () => {
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [selectedOptions, setSelectedOptions] = useState([])

  const HealthScheme = selectedOptions => {
    setSelectedOptions(selectedOptions)
  }

  const [Actin, setActin] = useState([])

  useEffect(() => {
    GetProducts()
  }, [])

  const GetProducts = () => {
    var token = datas

    axios
      .post(
        URLS.GetProduct,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.products)
      })
  }

  const options = Actin.map(response => ({
    value: response._id,
    label: response.name,
  }))

  const [inputList, setInputList] = useState([{ benefits: "" }])

  const handleInputChange = (e, index) => {
    const { name, value } = e.target
    const list = [...inputList]
    list[index][name] = value
    setInputList(list)
  }

  const handleRemoveClick = index => {
    const list = [...inputList]
    list.splice(index, 1)
    setInputList(list)
  }

  const handleAddClick = () => {
    setInputList([...inputList, { benefits: "" }])
  }

  const [form, setform] = useState([])

  let history = useHistory()

  const handleChange = e => {
    const myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  const Addplans = () => {
    const token = datas

    const dataArray = {
      name: form.name,
      validity: form.validity,
      price: form.price,
      discount: form.discount,
      description: form.description,
      products: selectedOptions,
      discountType: form.discountType,
      benefits: inputList,
    }

    axios
      .post(URLS.AddSubscription, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            history.push(
              "/Subscription",
              sessionStorage.setItem("tost", "Plan has been Added successfully")
            )
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const formsubmit = e => {
    e.preventDefault()
    Addplans()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Od Cards" breadcrumbItem="Add Plan" />
          <Row>
            <Col>
              <Card className="p-4">
                <Form
                  onSubmit={e => {
                    formsubmit(e)
                  }}
                >
                  <Row>
                    <Col md="6" className="mt-2">
                      <Label>Plan Name</Label>
                      <span className="text-danger">*</span>
                      <Input
                        onChange={e => {
                          handleChange(e)
                        }}
                        value={form.name}
                        name="name"
                        required
                        type="text"
                        placeholder="Enter Name"
                      />
                    </Col>

                    <Col md="6" className="mt-2">
                      <Label>Plan Validity</Label>
                      <span className="text-danger">*</span>
                      <select
                        onChange={e => {
                          handleChange(e)
                        }}
                        value={form.validity}
                        name="validity"
                        className="form-select"
                        required
                      >
                        <option value="">Select Type</option>
                        <option value="1 Month">1 Month</option>
                        <option value="3 Months">3 Months</option>
                        <option value="6 Months">6 Months</option>
                        <option value="9 Months">9 Months</option>
                        <option value="12 Months">12 Months</option>
                      </select>
                    </Col>

                    <Col md="6" className="mt-3">
                      <Label>Plan Price</Label>
                      <span className="text-danger">*</span>
                      <Input
                        onChange={e => {
                          handleChange(e)
                        }}
                        type="number"
                        required
                        name="price"
                        value={form.price}
                        placeholder="Enter Plan Price"
                      />
                    </Col>

                    <Col md="6" className="mt-3">
                      <Label>Discount Type</Label>
                      <span className="text-danger">*</span>
                      <select
                        onChange={e => {
                          handleChange(e)
                        }}
                        value={form.discountType}
                        name="discountType"
                        className="form-select"
                        required
                      >
                        <option value="">Select Type</option>
                        <option value="percentage">Percentage</option>
                        <option value="price">Price</option>
                      </select>
                    </Col>

                    

                    <Col md="6" className="mt-3">
                      {form.discountType == "percentage" ? (
                        <>
                          <Label> Percentage Discount</Label>
                        </>
                      ) : (
                        <>
                          <Label> Price Discount</Label>
                        </>
                      )}
                      <span className="text-danger">*</span>
                      <Input
                        onChange={e => {
                          handleChange(e)
                        }}
                        name="discount"
                        required
                        value={form.discount}
                        type="number"
                        placeholder="Enter Discount"
                      />
                    </Col>

                    <Col lg="6" className="mt-3">
                      <div>
                        <Label for="basicpill-firstname-input1">
                          Products <span className="text-danger">*</span>
                        </Label>
                        <Select
                          options={options}
                          placeholder="Products"
                          value={selectedOptions}
                          onChange={HealthScheme}
                          isSearchable={true}
                          isMulti
                        />
                      </div>
                    </Col>
                    <Col md={6} className="mt-3">
                      <div>
                        <Label>Plan Benefits </Label>
                        {inputList.map((x, i) => {
                          return (
                            <>
                              <Row>
                                <div key={i} className="box row">
                                  <Col md="9" className="mb-1">
                                    <Input
                                      type="text"
                                      name="benefits"
                                      placeholder="Enter Plan Benefits"
                                      value={x.benefits}
                                      onChange={e => handleInputChange(e, i)}
                                    />
                                  </Col>

                                  <Col sm="3">
                                    <div className="btn-box">
                                      {inputList.length !== 1 && (
                                        <button
                                          className="mr10 btn btn-outline-danger btn-sm m-1"
                                          type="button"
                                          onClick={() => handleRemoveClick(i)}
                                        >
                                          Remove
                                          <i className="bx bx-x-circle"></i>
                                        </button>
                                      )}
                                      {inputList.length - 1 === i && (
                                        <button
                                          className="btn btn-sm btn-outline-info m-1"
                                          onClick={handleAddClick}
                                        >
                                          Add
                                          <i className="bx bx-plus-circle"></i>
                                        </button>
                                      )}
                                    </div>
                                  </Col>
                                </div>
                              </Row>
                            </>
                          )
                        })}
                      </div>
                    </Col>

                    <Col md={6} className="mt-3">
                      <Label>Description</Label>
                      <span className="text-danger">*</span>
                      <textarea
                        onChange={e => {
                          handleChange(e)
                        }}
                        type="text"
                        name="description"
                        required
                        rows={3}
                        value={form.description}
                        placeholder="Enter  Description"
                        className="form-control"
                      />
                    </Col>
                  </Row>

                  <div className="text-end">
                    <Button type="submit" color="success m-1">
                      Submit <i className="bx bx-check-circle"></i>
                    </Button>
                  </div>
                </Form>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default Addplan
