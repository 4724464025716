const Url = "https://api.odcards.com//"

export const URLS = {
  Base: Url,

  //profile
  getProfile: Url + "v1/odcardsapi/admin/auth/getadminprofile",
  UpdateProfile: Url + "v1/odcardsapi/admin/auth/updateadminprofile",
  UpdateImage: Url + "v1/odcardsapi/admin/auth/editadminprofile",
  ChangePass: Url + "v1/odcardsapi/admin/auth/changepass",

  //Department
  AddDepartment: Url + "v1/odcardsapi/admin/department/adddepartment",
  GetDepartment: Url + "v1/odcardsapi/admin/department/getdepartments",
  GetDepartmentSearch:
    Url + "v1/odcardsapi/admin/department/getdepartments?searchQuery=",
  UpdateDepartment: Url + "v1/odcardsapi/admin/department/editdepartment",
  InActiveDepartment: Url + "v1/odcardsapi/admin/department/deletedepartment",

  //Role
  AddRole: Url + "v1/odcardsapi/admin/role/addrole",
  GetRole: Url + "v1/odcardsapi/admin/role/getAll",
  GetRoleSearch: Url + "v1/odcardsapi/admin/role/getAll?searchQuery=",
  GetOneRole: Url + "v1/odcardsapi/admin/role/getrole",
  EditRole: Url + "v1/odcardsapi/admin/role/editrole",
  DeleteRole: Url + "v1/odcardsapi/admin/role/deleterole/",

  //Staff
  AddStaff: Url + "v1/odcardsapi/admin/staff/addstaff",
  GetStaff: Url + "v1/odcardsapi/admin/staff/getAllstaff",
  GetStaffSearch: Url + "v1/odcardsapi/admin/staff/getAllstaff?searchQuery=",
  EditStaff: Url + "v1/odcardsapi/admin/staff/editstaff/",
  DeleteStaff: Url + "v1/odcardsapi/admin/staff/deletestaff/",

  //StaffGroups
  AddStaffGroups: Url + "v1/odcardsapi/admin/usergroup/addusergroup",
  GetStaffGroups: Url + "v1/odcardsapi/admin/usergroup/getallusergroups",
  GetStaffGroupsSearch:
    Url + "v1/odcardsapi/admin/usergroup/getallusergroups?searchQuery=",
  EditStaffGroups: Url + "v1/odcardsapi/admin/usergroup/editusergroup/",
  DeleteStaffGroups: Url + "v1/odcardsapi/admin/usergroup/deleteusergroup/",

  //ContactUs
  GetContactUs: Url + "v1/odcardsapi/admin/contactus/getcontactus",
  UpdatContactUs: Url + "v1/odcardsapi/admin/contactus/editcontactus",

  //Settings
  GetSetting: Url + "v1/odcardsapi/admin/setting/getsetting",
  UpdateRefundPolicy: Url + "v1/odcardsapi/admin/setting/updaterefundpolicy",
  UpdatePRIVACYPOLICY: Url + "v1/odcardsapi/admin/setting/updateprivacypolicy",
  UpdateTERMSANDCONDITION:
    Url + "v1/odcardsapi/admin/setting/updatetermsandcondition",

  //Faqs
  AddFaqs: Url + "v1/odcardsapi/admin/faq/addfaq",
  GetFaqs: Url + "v1/odcardsapi/admin/faq/getallfaqs",
  GetFaqsSearch: Url + "v1/odcardsapi/admin/faq/getallfaqs?searchQuery=",
  UpdateFaqs: Url + "v1/odcardsapi/admin/faq/editfaq",
  InActiveFaqs: Url + "v1/odcardsapi/admin/faq/deletefaq",

  //HomeSliders
  AddHomeslides: Url + "v1/odcardsapi/admin/homeimageslider/addhomeimageslider",
  GetHomeslides:
    Url + "v1/odcardsapi/admin/homeimageslider/getallhomeimagesliders",
  GetHomeslidesSearch:
    Url +
    "v1/odcardsapi/admin/homeimageslider/getallhomeimagesliders?searchQuery=",
  UpdateHomeslides:
    Url + "v1/odcardsapi/admin/homeimageslider/edithomeimageslider/",
  DeleteHomeslides:
    Url + "v1/odcardsapi/admin/homeimageslider/deletehomeimageslider/",

  //Content
  GetHomeContent: Url + "v1/odcardsapi/admin/homeslider/gethomeslider",
  UpdatHomeContent: Url + "v1/odcardsapi/admin/homeslider/updatehomeslider",

  //About
  GetAbout: Url + "v1/odcardsapi/admin/aboutus/getaboutus",
  UpdatAbout: Url + "v1/odcardsapi/admin/aboutus/updateaboutus",

  //Product Category
  AddProductCategory: Url + "v1/odcardsapi/admin/category/addcategory",
  GetProductCategory: Url + "v1/odcardsapi/admin/category/getallcategorys",
  GetProductCategorySearch:
    Url + "v1/odcardsapi/admin/category/getallcategorys?searchQuery=",
  UpdateProductCategory: Url + "v1/odcardsapi/admin/category/editcategory/",
  DeleteProductCategory: Url + "v1/odcardsapi/admin/category/deletecategory/",

  //Product Key Feature
  AddProductKeyFeature: Url + "v1/odcardsapi/admin/subcategory/addsubcategory",
  GetProductKeyFeature:
    Url + "v1/odcardsapi/admin/subcategory/getallsubcategorys",
  GetProductKeyFeatureSearch:
    Url + "v1/odcardsapi/admin/subcategory/getallsubcategorys?searchQuery=",
  UpdateProductKeyFeature:
    Url + "v1/odcardsapi/admin/subcategory/editsubcategory/",
  DeleteProductKeyFeature:
    Url + "v1/odcardsapi/admin/subcategory/deletesubcategory/",

  //Product
  AddProduct: Url + "v1/odcardsapi/admin/product/addproduct",
  GetProduct: Url + "v1/odcardsapi/admin/product/getallproducts",
  GetProductSearch:
    Url + "v1/odcardsapi/admin/product/getallproducts?searchQuery=",
  UpdateProduct: Url + "v1/odcardsapi/admin/product/editproduct/",
  DeleteProduct: Url + "v1/odcardsapi/admin/product/deleteproduct/",
  GetoneProduct: Url + "v1/odcardsapi/admin/product/getproductbyid",
  GetByidProductVeriation: Url + "v1/odcardsapi/admin/getproductId",

  //Product Offers
  AddProductOffers: Url + "v1/odcardsapi/admin/productoffer/addproductoffer",
  GetProductOffers:
    Url + "v1/odcardsapi/admin/productoffer/getallproductoffers",
  GetProductOffersSearch:
    Url + "v1/odcardsapi/admin/productoffer/getallproductoffers?searchQuery=",
  UpdateProductOffers:
    Url + "v1/odcardsapi/admin/productoffer/updateproductoffer/",
  DeleteProductOffers:
    Url + "v1/odcardsapi/admin/productoffer/deleteproductoffer/",

  //Product Varitaion
  AddProductVaritaion:
    Url + "v1/odcardsapi/admin/productvariation/addproductvariation",
  GetProductVaritaion:
    Url + "v1/odcardsapi/admin/productvariation/getallproductvariations",
  GetProductVaritaionSearch:
    Url +
    "v1/odcardsapi/admin/productvariation/getallproductvariations?searchQuery=",
  UpdateProductVaritaion:
    Url + "v1/odcardsapi/admin/productvariation/editproductvariation/",
  DeleteProductVaritaion:
    Url + "v1/odcardsapi/admin/productvariation/deleteproductvariation/",

  //Coupon
  AddCoupon: Url + "v1/odcardsapi/admin/coupon/addcoupon",
  GetCoupon: Url + "v1/odcardsapi/admin/coupon/getallcoupons",
  GeCouponSearch: Url + "v1/odcardsapi/admin/coupon/getallcoupons?searchQuery=",
  UpdateCoupon: Url + "v1/odcardsapi/admin/coupon/editcoupon",
  DeleteCoupon: Url + "v1/odcardsapi/admin/coupon/deletecoupon",

  //Subscription
  AddSubscription: Url + "v1/odcardsapi/admin/subscription/addsubscription",
  GetSubscription: Url + "v1/odcardsapi/admin/subscription/getallsubscriptions",
  GeSubscriptionSearch:
    Url + "v1/odcardsapi/admin/subscription/getallsubscriptions?searchQuery=",
  UpdateSubscription:
    Url + "v1/odcardsapi/admin/subscription/editsubscription/",
  DeleteSubscription:
    Url + "v1/odcardsapi/admin/subscription/deletesubscription/",
  GetbyidSubscription:
    Url + "v1/odcardsapi/admin/subscription/getsubscriptionByid",

  //Users
  GetAllUsers: Url + "v1/odcardsapi/admin/getallusers",
  GetAllUsersSearch: Url + "v1/odcardsapi/admin/getallusers?searchQuery=",

  //SubscriptionUsers
  AddSubscriptionUsers:
    Url + "v1/odcardsapi/admin/subscriptionuser/addsubscriptionuser",
  GetSubscriptionUsers:
    Url + "v1/odcardsapi/admin/subscriptionuser/getallsubscriptionusers",
  UpdateSubscriptionUsers:
    Url + "v1/odcardsapi/admin/subscriptionuser/editsubscriptionuser/",
  DeleteSubscriptionUsers:
    Url + "v1/odcardsapi/admin/subscriptionuser/deletesubscriptionuser/",

  //Enquiry
  GetEnquiry: Url + "v1/odcardsapi/admin/getallenquiries",
  GetEnquirySearch: Url + "v1/odcardsapi/admin/getallenquiries?searchQuery=",
  DeleteEnquiry: Url + "v1/odcardsapi/admin/deleteenquiry/",

  //PendingBookings
  GetPendingBookings: Url + "v1/odcardsapi/admin/order/getallorders",
  GetPendingBookingsSearch:
    Url + "v1/odcardsapi/admin/order/getallorders?searchQuery=",
  UpdatePendingBookings: Url + "v1/odcardsapi/admin/order/updatestatus",

  GetPendingBookingsFilters: Url + "v1/odcardsapi/admin/order/getfilterdetails",


  GetCancelBookings: Url + "v1/odcardsapi/admin/order/getcancelledorders",
  UpdateCancelBookings: Url + "v1/odcardsapi/admin/order/approvecancellation/",


  //Payments
  GetPayments: Url + "v1/odcardsapi/admin/getallpayments",
  GetCompleatedBookingsSearch:
    Url + "v1/odcardsapi/admin/getallpayments?searchQuery=",

  //Dashboard
  GetDashboard: Url + "v1/odcardsapi/admin/getdashboard",
  GetUserAddress: Url + "v1/odcardsapi/admin/getuserbyid",
  GetUserSubscriptions: Url + "v1/odcardsapi/admin/getallsubscribers",
  GetUserSubscriptionsSearch:
    Url + "v1/odcardsapi/admin/getallsubscribers?searchQuery=",
  UserUpdates: Url + "v1/odcardsapi/admin/updateuserid",
}
