import React, { useState, useEffect } from "react"
import { CardBody, Container, Row, Col, Card, Button } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import { URLS } from "../../Url"
import axios from "axios"
import StarRatings from "react-star-ratings"
import { Link } from "react-router-dom"

function RecruitView() {
  
  const history = useHistory()

  useEffect(() => {
    GetOneActins()
  }, [])

  const [form, setform] = useState([])

  const Actinid = sessionStorage.getItem("Productsids")

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const GetOneActins = () => {
    const data = {
      id: Actinid,
    }

    var token = datas
    axios
      .post(URLS.GetoneProduct, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setform(res.data.product[0])
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Od Cards Admin" breadcrumbItem="View Product" />
          <Row>
            <Col>
              <Button
                onClick={() => history.goBack()}
                className="mb-3  m-1 "
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i> Back
              </Button>
            </Col>
          </Row>

          <Card>
            <CardBody>
              <Row>
                <Col lg={4}>
                  <h5 className="mb-3 text-primary">Product Details : </h5>
                  <ul className="list-unstyled vstack gap-3 mb-0">
                    <img src={URLS.Base + form.image} height="150px"></img>
                  </ul>
                </Col>
                <Col lg={5}>
                  <div className="mt-4 mt-xl-3 pt-4">
                    <Link to="#" className="text-primary">
                      {form.category}
                    </Link>
                    <h4 className="mt-1 mb-3">{form.name}</h4>

                    <div className="text-muted float-start me-3">
                      <StarRatings
                        rating={4}
                        starRatedColor="#F1B44C"
                        starEmptyColor="#2D363F"
                        numberOfStars={5}
                        name="rating"
                        starDimension="14px"
                        starSpacing="3px"
                      />
                    </div>
                    <p className="text-muted mb-4">({form.rating})</p>
                    <br></br>
                    <h5 className="mb-4">
                      Price : <b>{form.price} </b>
                    </h5>

                    <p className="text-muted mb-4">{form.description}</p>

                    <h5>
                      Model :{" "}
                      <b style={{ fontSize: "14px", color: "rgb(73,80,87)" }}>
                        SRK15CVV-W6
                      </b>
                    </h5>

                    <h5>
                      Refrigant :{" "}
                      <b style={{ fontSize: "14px", color: "rgb(73,80,87)" }}>
                        SRK15CVV-W6
                      </b>
                    </h5>

                    <h5>
                      Tonnage :{" "}
                      <b style={{ fontSize: "14px", color: "rgb(73,80,87)" }}>
                        SRK15CVV-W6
                      </b>
                    </h5>
                  </div>
                </Col>
                <Col lg={3}>
                  <h5 className="mb-3 text-primary">Key Features :-</h5>
                  {form.keyFeatures &&
                    form.keyFeatures.map((item, i) => (
                      <div key={i}>
                        <p className="text-muted">
                          <img src={URLS.Base + item.image} width="50px"></img>
                          <span className="p-2">{item.label}</span>
                        </p>
                      </div>
                    ))}
                </Col>
              </Row>
              <h5 className="mb-3 mt-5 pt-5 text-primary">
                Product Specifications :-{" "}
              </h5>
              <hr></hr>
              <Row>
                <Col lg={12}>
                  <div style={{ width: "100%" }}>
                    <div
                      className="mt-2"
                      dangerouslySetInnerHTML={{
                        __html: form.specifications,
                      }}
                    ></div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default RecruitView
