import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody, Input, Button, Table } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import { Link, useHistory } from "react-router-dom"
import ReactPaginate from "react-paginate"
import { URLS } from "../../Url"
import axios from "axios"

function Ventures() {
  const [Actin, setActin] = useState([])

  const history = useHistory()

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  useEffect(() => {
    GetProducts()
    datass()
  }, [])

  const GetProducts = () => {
    var token = datas

    axios
      .post(
        URLS.GetSubscription,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.subscriptions)
      })
  }

  const pagesVisited = pageNumber * listPerPage
  const lists = Actin.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Actin.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const Actinid = data => {
    sessionStorage.setItem("Subscriptionid", data._id)
    history.push("/EditSubscription")
  }

  const datass = () => {
    const location = sessionStorage.getItem("tost")
    if (location != "") {
      toast(location)
      sessionStorage.clear()
    } else {
      sessionStorage.clear()
    }
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const [Searchs, setSearchs] = useState([])

  const Search = e => {
    let myUser = { ...Searchs }
    myUser[e.target.name] = e.target.value
    setSearchs(myUser)
    var token = datas

    axios
      .post(
        URLS.GeSubscriptionSearch + `${e.target.value}`,
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.subscriptions)
      })
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      DeleteBanner(data)
    }
  }
  const DeleteBanner = data => {
    var token = datas
    var remid = data._id
    axios
      .delete(URLS.DeleteSubscription + remid, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetProducts()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var Roles = data?.rolesAndPermission[0]

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Od Cards Admin" breadcrumbItem="Plans list" />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    {Roles?.PlansAdd == true || Roles?.accessAll == true ? (
                      <>
                        <Col>
                          <Link to="/AddSubscription">
                            <Button color="primary">
                              New Plan <i className="bx bx-plus-circle"></i>
                            </Button>
                          </Link>
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                    <Col>
                      <div style={{ float: "right" }}>
                        <Input
                          type="search"
                          name="search"
                          value={Searchs.search}
                          onChange={Search}
                          className="form-control"
                          placeholder="Search.."
                          autoComplete="off"
                        />
                      </div>
                    </Col>
                  </Row>

                  <div className="table-rep-plugin mt-4 table-responsive">
                    <Table hover className="table table-bordered mb-4 ">
                      <thead>
                        <tr className="text-center">
                          <th>SlNo</th>
                          <th>Plan Name</th>
                          <th>Plan Validity</th>
                          <th>Plan Price</th>
                          <th>Discount</th>
                          <th>Products </th>
                          <th>Plan Benefits</th>
                          <th>Description</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lists.map((data, key) => (
                          <tr key={key} className="text-center">
                            <td>{(pageNumber - 1) * 5 + key + 6}</td>
                            <td>{data.name}</td>
                            <td>{data.validity}</td>
                            <td>{data.price}</td>
                            <td>
                              {data.discountType == "percentage" ? (
                                <>{data.discount} % </>
                              ) : (
                                <>{data.discount} /-</>
                              )}
                            </td>
                            <td>
                              {data.products.map((datas, i) => (
                                <p key={i}>
                                  {i + 1}){datas.label}
                                </p>
                              ))}
                            </td>
                            <td>
                              {data.benefits.map((datass, j) => (
                                <p key={j}>
                                  {j + 1}){datass.benefits}
                                </p>
                              ))}
                            </td>
                            <td>{data.description}</td>

                            <td>
                              {Roles?.PlansEdit == true ||
                              Roles?.accessAll == true ? (
                                <>
                                  <Button
                                    onClick={() => {
                                      Actinid(data)
                                    }}
                                    style={{
                                      padding: "6px",
                                      margin: "3px",
                                    }}
                                    size="sm"
                                    className="m-1"
                                    outline
                                    color="success"
                                  >
                                    <i className="bx bx-edit"></i>
                                  </Button>{" "}
                                </>
                              ) : (
                                ""
                              )}
                              {Roles?.PlansDelete == true ||
                              Roles?.accessAll == true ? (
                                <>
                                  <Button
                                    onClick={() => {
                                      manageDelete(data)
                                    }}
                                    style={{
                                      padding: "6px",
                                      margin: "3px",
                                    }}
                                    color="danger"
                                    outline
                                  >
                                    <i className="bx bx-trash"></i>
                                  </Button>{" "}
                                </>
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div
                      className="d-flex mt-3 mb-1"
                      style={{ float: "right" }}
                    >
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                        total={lists.length}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <ToastContainer />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Ventures
