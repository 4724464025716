import React, { useState, useEffect } from "react"
import { CardBody, Container, Row, Col, Card, Button, Table } from "reactstrap"
import { ToastContainer, toast } from "react-toastify"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { URLS } from "../../Url"
import axios from "axios"
import JSZip from "jszip"

function RecruitView() {
  useEffect(() => {
    GetOneActins()
  }, [])

  const [Product, setProduct] = useState([])

  const [Images, setImages] = useState([])

  const [Products, setProducts] = useState([])

  const [ProductName, setProductName] = useState([])

  const [Payments, setPayments] = useState([])

  const Actinid = localStorage.getItem("bookingids")

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const GetOneActins = () => {
    const data = {
      id: Actinid,
    }

    var token = datas
    axios
      .post(
        "https://api.odcards.com//v1/odcardsapi/admin/order/getorderbyid",
        data,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setProduct(res.data.order)
        setProducts(res.data.order.products)
        setImages(res.data.order.products)
        setPayments(res.data.order.payment)
        setProductName(res.data.order.products[0])
      })
  }

  const createZip = async () => {
    if (Product.status == "pending") {
      EditStatus()
    }

    const zip = new JSZip()

    // Loop through each object in the Images array
    await Promise.all(
      Images.map(async (image, imageIndex) => {
        const imageFolder = zip.folder(
          `${Product.userCode}/${ProductName.productName}/${imageIndex + 1}`
        )

        // Loop through each orderImage in the current image object
        await Promise.all(
          image.orderImage.map(async (imageUrl, orderImageIndex) => {
            const imageBlob = await fetchImageAsBlob(`${URLS.Base}${imageUrl}`)
            const extension = imageUrl
              .substring(imageUrl.lastIndexOf(".") + 1)
              .toLowerCase() // Extract extension from URL
            // Add each image to the current imageFolder in the ZIP file with original file extension
            imageFolder.file(
              `${Product.userCode}_${ProductName.productName}_${Product.orderId}_${orderImageIndex + 1}.${extension}`,
              imageBlob
            )
          })
        )
      })
    )

    // Prepare content for BookingDetails.txt file
    const scriptContent =
      Products.map(
        data =>
          `\nProduct Name  : ${data.productName}\nProduct Price  : ${data.productvariationprice}\nProduct Quantity  : ${data.productvariationvalue}\nProduct Height  : ${data.productVariationHeight}\nProduct Width  : ${data.productVariationWidth}`
      ).join("\n") +
      `\n \nUser Name  : ${Product.userName}\nUser Phone Number  : ${Product.userphone}\nUser Email  : ${Product.useremail}`

    // Add BookingDetails.txt file to the root of the ZIP file
    zip.file("BookingDetails.txt", scriptContent)

    // Generate the ZIP file asynchronously
    const zipBlob = await zip.generateAsync({ type: "blob" })

    // Create a download link for the ZIP file
    const downloadZipLink = document.createElement("a")
    downloadZipLink.href = URL.createObjectURL(zipBlob)
    downloadZipLink.download = `${Product.userCode}_${ProductName.productName}.zip`

    // Trigger the download
    downloadZipLink.click()

    // Clean up
    URL.revokeObjectURL(downloadZipLink.href)
  }

  const fetchImageAsBlob = async imageUrl => {
    const response = await fetch(imageUrl)
    const blob = await response.blob()
    return blob
  }

  const Actinids = () => {
    window.open("/PendingBookings", "_self")
  }

  const EditStatus = () => {
    var token = datas
    var formid = Actinid
    const dataArray = {
      status: "processed",
    }
    axios
      .put(URLS.UpdatePendingBookings + "/" + formid, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            GetOneActins()
            toast(res.data.message)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Od Cards Admin" breadcrumbItem="View Bookings" />
          <Row>
            <Col>
              <Button
                onClick={() => Actinids()}
                className="mb-3  m-1 "
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i> Back
              </Button>
              <div>
                <Button
                  onClick={createZip}
                  style={{ float: "right" }}
                  color="success"
                  className="mb-3  m-1"
                >
                  Download Zip File
                </Button>
              </div>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <Row>
                <Col lg={6}>
                  <h5 className="mb-4 text-primary">User Details : </h5>
                </Col>
                <Col lg={6}>
                  <div style={{ float: "right" }}>
                    <h6 className="mb-4 text-warning">Status: {Product.status} </h6>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <ul className="verti-timeline list-unstyled">
                    <li className="event-list">
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <h6 className="font-size-14 ">User Id:</h6>
                            <p className="text-muted">{Product.userCode}</p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="event-list">
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <h6 className="font-size-14 ">
                              User Phone Number:
                            </h6>
                            <p className="text-muted">{Product.userphone}</p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="event-list">
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <h6 className="font-size-14 ">
                              User Alternate Phone Number:
                            </h6>
                            <p className="text-muted">
                              {Product.alternatePhone}
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="event-list">
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <h6 className="font-size-14 ">User Email:</h6>
                            <p className="text-muted">{Product.useremail}</p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="event-list">
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <h6 className="font-size-14 ">Business Name:</h6>
                            <p className="text-muted">{Product.businessName}</p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="event-list">
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <h6 className="font-size-14 ">
                              User Address Type:
                            </h6>
                            <p className="text-muted">{Product.addressType}</p>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Col>

                <Col lg={6} className="mt-3">
                  <ul className="verti-timeline list-unstyled">
                    <li className="event-list">
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <h6 className="font-size-14 ">User Name:</h6>
                            <p className="text-muted">{Product.userName}</p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="event-list">
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <h6 className="font-size-14 ">Gst Number:</h6>
                            <p className="text-muted">{Product.gstNumber}</p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="event-list">
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <h6 className="font-size-14 ">LandMark:</h6>
                            <p className="text-muted">{Product.landmark}</p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="event-list">
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <h6 className="font-size-14 ">Address:</h6>
                            <p className="text-muted">{Product.address}</p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="event-list">
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <h6 className="font-size-14 ">Town / City:</h6>
                            <p className="text-muted">{Product.city}</p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="event-list">
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <h6 className="font-size-14 ">Pin Code:</h6>
                            <p className="text-muted">{Product.pincode}</p>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <h5 className="mb-2 text-primary">Payments Details : </h5>
              <div className="row">
                <div className="col-md-6 pt-4">
                  <h6 className="font-size-14 ">Transaction Id :</h6>
                  <span>{Payments.transactionId}</span>
                </div>
                <div className="col-md-6 pt-4">
                  <h6 className="font-size-14 ">Coupon Code :</h6>
                  <span>{Payments.couponCode}</span>
                </div>
                <div className="col-md-6 pt-4">
                  <h6 className="font-size-14 ">Coupon Discount :</h6>
                  <span>{Payments.couponDiscount}</span>
                </div>
                <div className="col-md-6 pt-4">
                  <h6 className="font-size-14 "> Delivery :</h6>
                  <span>40</span>
                </div>
                <div className="col-md-6  pt-4">
                  <h6 className="font-size-14 "> Total Amount :</h6>
                  <span>{Payments.totalAmount}</span>
                </div>
                <div className="col-md-6  pt-4">
                  <h6 className="font-size-14 "> Status :</h6>
                  <span>{Payments.status}</span>
                </div>
              </div>
            </CardBody>
          </Card>

          {Products.map((data, i) => (
            <Card key={i}>
              <CardBody>
                <Row>
                  <Col lg={4}>
                    <ul className="list-unstyled vstack gap-2 mb-0">
                      <h5 className="mb-4 text-primary">Order Image</h5>
                      {data.orderImage.map((file, index) => (
                        <a
                          href={URLS.Base + file}
                          target="_blank"
                          rel="noreferrer"
                          key={index}
                        >
                          <img
                            src={URLS.Base + file}
                            height="160px"
                            width="100%"
                          ></img>
                        </a>
                      ))}
                    </ul>
                  </Col>
                  <Col lg={8}>
                    <div className="table-rep-plugin table-responsive">
                      <h5 className="mb-4 text-primary">Order Details </h5>
                      <Table hover bordered responsive>
                        <tbody>
                          <tr>
                            <th>Catagory Name</th>
                            <td>{data.categoryName}</td>
                          </tr>
                          <tr>
                            <th>Sub Category Name</th>
                            <td>{data.subcategoryName}</td>
                          </tr>
                          <tr>
                            <th>Product Image</th>
                            <td>
                              <img
                                src={URLS.Base + data.productImage}
                                height="50px"
                              ></img>
                            </td>
                          </tr>
                          <tr>
                            <th>Product Name</th>
                            <td>{data.productName}</td>
                          </tr>
                          {data.productVariationSizes == "YES" ? (
                            <>
                              <tr>
                                <th>Product Height</th>
                                <td>{data.productVariationHeight}</td>
                              </tr>
                              <tr>
                                <th>Product Width</th>
                                <td>{data.productVariationWidth}</td>
                              </tr>
                            </>
                          ) : (
                            <></>
                          )}
                          <tr>
                            <th>Product Price </th>
                            <td>₹ {data.productvariationprice}</td>
                          </tr>
                          <tr>
                            <th>Product Quantity </th>
                            <td>Qnt : {data.productvariationvalue} </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          ))}

          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default RecruitView
