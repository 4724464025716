import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"
import { Link } from "react-router-dom"
import logo from "../../assets/images/logo.png"
import FAV from "../../assets/images/fav.png"
import { URLS } from "../../Url"
import axios from "axios"

const Sidebar = props => {
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [forms, setforms] = useState([])

  const GetAllBroucher = () => {
    var token = datas

    axios
      .post(
        URLS.GetContactUs,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setforms(res.data.contactus)
      })
  }
  useEffect(() => {
    GetAllBroucher()
  }, [])

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src={FAV} alt="" />
            </span>
            <span className="logo-lg">
              <img src={URLS.Base + forms.logo} alt="" />
            </span>
          </Link>

          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img src={FAV} alt="" />
            </span>
            <span className="logo-lg">
              <img src={URLS.Base + forms.logo} alt="" />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)))
