import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Modal,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { URLS } from "../../Url"

const Banner = () => {
  const [modal_small, setmodal_small] = useState(false)
  const [banner, setbanner] = useState([])
  const [form, setform] = useState([])
  const [form1, setform1] = useState([])

  function tog_small() {
    setmodal_small(!modal_small)
  }

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }
  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  useEffect(() => {
    GetAllBanners()
    GetProductCategory()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = banner.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(banner.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const handleSubmit = e => {
    e.preventDefault()
    AddBanner()
  }

  const AddBanner = () => {
    var token = datas
    const dataArray = {
      name: form.name,
      value: form.value,
      price: form.price,
      productId: form.productId,
      sizes: form.sizes,
      height: form.height,
      width: form.width,
    }

    axios
      .post(URLS.AddProductVaritaion, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetAllBanners()
            clearForm()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const EditBanner = () => {
    var token = datas
    var formid = form1._id
    const dataArray = {
      name: form1.name,
      value: form1.value,
      price: form1.price,
      productId: form1.productId,
      sizes: form1.sizes,
      height: form1.height,
      width: form1.width,
    }
    axios
      .put(URLS.UpdateProductVaritaion + formid, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetAllBanners()
            setmodal_small(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const DeleteBanner = data => {
    var token = datas
    var remid = data._id
    axios
      .delete(URLS.DeleteProductVaritaion + remid, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetAllBanners()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      DeleteBanner(data)
    }
  }

  const handleSubmit1 = e => {
    e.preventDefault()
    EditBanner()
  }

  const GetAllBanners = () => {
    var token = datas
    axios
      .post(
        URLS.GetProductVaritaion,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setbanner(res.data.productvariations)
        setIsLoading(false)
      })
  }

  const clearForm = () => {
    setform({
      name: "",
      productId: "",
      price: "",
      value: "",
      sizes: "",
      height: "",
      width: "",
    })
  }

  const getpopup = data => {
    setform1(data)
    tog_small()
  }

  const [search, setsearch] = useState([])

  const searchAll = e => {
    let myUser = { ...search }
    myUser[e.target.name] = e.target.value
    setsearch(myUser)

    var token = datas
    axios
      .post(
        URLS.GetProductVaritaionSearch + `${e.target.value}`,
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setbanner(res.data.productvariations)
      })
  }
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const [Category, setCategory] = useState([])

  const GetProductCategory = () => {
    var token = datas
    axios
      .post(
        URLS.GetProduct,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setCategory(res.data.products)
      })
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var Roles = data?.rolesAndPermission[0]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Od Cards Admin"
            breadcrumbItem="Product Variation"
          />
          <Row>
            {Roles?.ProductVariationAdd == true || Roles?.accessAll == true ? (
              <>
                <Col md={4}>
                  <Card>
                    <CardHeader className="bg-white">
                      <CardTitle>Add Product Variation</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Form
                        onSubmit={e => {
                          handleSubmit(e)
                        }}
                      >
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Product Name <span className="text-danger">*</span>
                          </Label>
                          <select
                            value={form.productId}
                            name="productId"
                            onChange={e => {
                              handleChange(e)
                            }}
                            className="form-select"
                          >
                            <option value="">Select</option>
                            {Category.map((data, key) => {
                              return (
                                <option key={key} value={data._id}>
                                  {data.name}
                                </option>
                              )
                            })}
                          </select>
                        </div>

                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Product Variation Name{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Product Variation Name"
                            required
                            name="name"
                            value={form.name}
                            onChange={e => {
                              handleChange(e)
                            }}
                          />
                        </div>

                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Product Sizes <span className="text-danger">*</span>
                          </Label>
                          <select
                            value={form.sizes}
                            name="sizes"
                            required
                            onChange={e => {
                              handleChange(e)
                            }}
                            className="form-select"
                          >
                            <option value="">Select</option>
                            <option value="YES">YES</option>
                            <option value="NO">NO</option>
                          </select>
                        </div>
                        {form.sizes == "YES" ? (
                          <>
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Height <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter Height"
                                required
                                name="height"
                                value={form.height}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </div>

                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Width <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter Width"
                                required
                                name="width"
                                value={form.width}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Quantity <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Quantity"
                            required
                            name="value"
                            value={form.value}
                            onChange={e => {
                              handleChange(e)
                            }}
                          />
                        </div>

                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Price <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Price"
                            required
                            name="price"
                            value={form.price}
                            onChange={e => {
                              handleChange(e)
                            }}
                          />
                        </div>

                        <div style={{ float: "right" }}>
                          <Button color="primary" type="submit">
                            Submit <i className="fas fa-check-circle"></i>
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </>
            ) : (
              ""
            )}
            <Col
              md={
                Roles?.ProductVariationAdd == true || Roles?.accessAll == true
                  ? 8
                  : 12
              }
            >
              <Card>
                <CardHeader className="bg-white">
                  <CardTitle>Product Variation List</CardTitle>
                </CardHeader>

                <CardBody>
                  <div>
                    <div className="table-responsive">
                      <div style={{ float: "right" }}>
                        <Input
                          type="text"
                          className="form-control"
                          placeholder="Search.."
                          value={search.search}
                          onChange={searchAll}
                          name="search"
                        />
                      </div>
                      <Table className="table table-bordered mb-4 mt-5">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Product Name</th>
                            <th>Product Variation Name</th>
                            <th>Sizes</th>
                            <th>Height</th>
                            <th>Width</th>
                            <th>Quantity</th>
                            <th>Price </th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {lists.map((data, key) => (
                            <tr key={key}>
                              <th>{(pageNumber - 1) * 5 + key + 6}</th>
                              <td>{data.productName}</td>
                              <td>{data.name}</td>
                              <td>{data.sizes}</td>
                              <td>{data.height}</td>
                              <td>{data.width}</td>
                              <td>{data.value}</td>
                              <td>{data.price}</td>
                              <td>
                                {Roles?.ProductVariationEdit == true ||
                                Roles?.accessAll == true ? (
                                  <>
                                    <Button
                                      onClick={() => {
                                        getpopup(data)
                                      }}
                                      className="mr-2"
                                      style={{
                                        padding: "6px",
                                        margin: "3px",
                                      }}
                                      color="success"
                                      outline
                                    >
                                      <i className="bx bx-edit "></i>
                                    </Button>
                                  </>
                                ) : (
                                  ""
                                )}
                                {Roles?.ProductVariationDelete == true ||
                                Roles?.accessAll == true ? (
                                  <>
                                    <Button
                                      onClick={() => {
                                        manageDelete(data)
                                      }}
                                      style={{
                                        padding: "6px",
                                        margin: "3px",
                                      }}
                                      color="danger"
                                      outline
                                    >
                                      <i className="bx bx-trash"></i>
                                    </Button>
                                  </>
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>

                      <div className="mt-3" style={{ float: "right" }}>
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          pageCount={pageCount}
                          onPageChange={changePage}
                          containerClassName={"pagination"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"disabled"}
                          activeClassName={"active"}
                          total={lists.length}
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal
          size="md"
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Edit Product Variation
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                handleSubmit1(e)
              }}
            >
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  Product Name <span className="text-danger">*</span>
                </Label>
                <select
                  value={form1.productId}
                  name="productId"
                  onChange={e => {
                    handleChange1(e)
                  }}
                  className="form-select"
                >
                  <option value="">Select</option>
                  {Category.map((data, key) => {
                    return (
                      <option key={key} value={data._id}>
                        {data.name}
                      </option>
                    )
                  })}
                </select>
              </div>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  Product Variation Name <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="basicpill-firstname-input1"
                  placeholder="Enter Product Variation Name"
                  required
                  name="name"
                  value={form1.name}
                  onChange={e => {
                    handleChange1(e)
                  }}
                />
              </div>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  Product Sizes <span className="text-danger">*</span>
                </Label>
                <select
                  value={form1.sizes}
                  name="sizes"
                  required
                  onChange={e => {
                    handleChange1(e)
                  }}
                  className="form-select"
                >
                  <option value="">Select</option>
                  <option value="YES">YES</option>
                  <option value="NO">NO</option>
                </select>
              </div>
              {form1.sizes == "YES" ? (
                <>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Height <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Height"
                      required
                      name="height"
                      value={form1.height}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>

                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Width <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Width"
                      required
                      name="width"
                      value={form1.width}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </>
              ) : (
                ""
              )}

              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  Quantity <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="basicpill-firstname-input1"
                  placeholder="Enter Quantity"
                  required
                  name="value"
                  value={form1.value}
                  onChange={e => {
                    handleChange1(e)
                  }}
                />
              </div>

              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  Price <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="basicpill-firstname-input1"
                  placeholder="Enter Price"
                  required
                  name="price"
                  value={form1.price}
                  onChange={e => {
                    handleChange1(e)
                  }}
                />
              </div>

              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="primary" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default Banner
