import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const ref = useRef()

  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var Roles = data?.rolesAndPermission[0]

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            {Roles?.Dashview == true || Roles?.accessAll == true ? (
              <>
                <li>
                  <Link to="/dashboard">
                    <i className="fas fa-home"></i>
                    <span>{props.t("Dashboard")}</span>
                  </Link>
                </li>
              </>
            ) : (
              ""
            )}

            {Roles?.staffView == true || Roles?.accessAll == true ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bxs-wallet-alt"></i>
                    <span>{props.t("Staff Managment")}</span>
                  </Link>
                  <ul className="sub-menu">
                    {Roles?.departementView == true ||
                    Roles?.accessAll == true ? (
                      <>
                        <li>
                          <Link to="/Departments">
                            {props.t("Departments")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.rolesAndPermissionView == true ||
                    Roles?.accessAll == true ? (
                      <>
                        <li>
                          <Link to="/RolesPremissions">
                            {props.t("Roles & Premissions")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.staffView == true || Roles?.accessAll == true ? (
                      <>
                        <li>
                          <Link to="/Staff">{props.t("Staff")}</Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.StaffgroupView == true ||
                    Roles?.accessAll == true ? (
                      <>
                        <li>
                          <Link to="/StaffGroups">
                            {props.t("Staff Groups")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}

            {Roles?.HomeManagmentView == true || Roles?.accessAll == true ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bxs-collection"></i>
                    <span>{props.t("Home Sliders")}</span>
                  </Link>
                  <ul className="sub-menu">
                    {Roles?.HomesliderView == true ||
                    Roles?.accessAll == true ? (
                      <>
                        <li>
                          <Link to="/HomeSlide">{props.t("Home Sliders")}</Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.HomeContentView == true ||
                    Roles?.accessAll == true ? (
                      <>
                        <li>
                          <Link to="/HomeContent">
                            {props.t("Home Content")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}

            {Roles?.AboutUsView == true || Roles?.accessAll == true ? (
              <>
                <li>
                  <Link to="/Aboutus">
                    <i className="bx bxs-help-circle"></i>
                    <span>{props.t("About Us")}</span>
                  </Link>
                </li>
              </>
            ) : (
              ""
            )}

            {Roles?.ProductManagmentView == true || Roles?.accessAll == true ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bxs-map-alt"></i>
                    <span>{props.t("Products")}</span>
                  </Link>
                  <ul className="sub-menu">
                    {Roles?.ProductCategoryView == true ||
                    Roles?.accessAll == true ? (
                      <>
                        <li>
                          <Link to="/ProductsCat">
                            {props.t("Product Category")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}

                    {Roles?.ProductSubCategoryView == true ||
                    Roles?.accessAll == true ? (
                      <>
                        <li>
                          <Link to="/ProductsKeyFeatures">
                            {props.t("Product Sub Category")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}

                    {Roles?.ProductAdd == true || Roles?.accessAll == true ? (
                      <>
                        <li>
                          <Link to="/AddProduct">{props.t("Add Product")}</Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}

                    {Roles?.ProductView == true || Roles?.accessAll == true ? (
                      <>
                        <li>
                          <Link to="/Product">{props.t("Products")}</Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}

                    {Roles?.ProductVariationView == true ||
                    Roles?.accessAll == true ? (
                      <>
                        <li>
                          <Link to="/ProductVariation">
                            {props.t("Product Variation")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}

                    {Roles?.ProductOfferView == true ||
                    Roles?.accessAll == true ? (
                      <>
                        <li>
                          <Link to="/ProductsOffers">
                            {props.t("Products Offers")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}

            {Roles?.UsersManagmentView == true || Roles?.accessAll == true ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bxs-user"></i>
                    <span>{props.t("Users List")}</span>
                  </Link>
                  <ul className="sub-menu">
                    {Roles?.UsersView == true || Roles?.accessAll == true ? (
                      <>
                        <li>
                          <Link to="/Users">{props.t("Users")}</Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}

                    {Roles?.UsersSubscriptionView == true ||
                    Roles?.accessAll == true ? (
                      <>
                        <li>
                          <Link to="/UserSubscriptionList">
                            {props.t("User Subscriptions")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}

                    {Roles?.UsersSubscriptionView == true ||
                    Roles?.accessAll == true ? (
                      <>
                        <li>
                          <Link to="/UserSubscriptionPayments">
                            {props.t("Subscriptions Payments")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}

            {Roles?.PlansManagmentView == true || Roles?.accessAll == true ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="fas fa-map"></i>
                    <span>{props.t("Plans")}</span>
                  </Link>

                  <ul className="sub-menu">
                    {Roles?.PlansAdd == true || Roles?.accessAll == true ? (
                      <>
                        <li>
                          <Link to="/AddSubscription">
                            {props.t("Add Plan ")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.PlansView == true || Roles?.accessAll == true ? (
                      <>
                        <li>
                          <Link to="/Subscription">
                            {props.t("Plans List")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.PlanAccessView == true ||
                    Roles?.accessAll == true ? (
                      <>
                        <li>
                          <Link to="/SubscriptionUsers">
                            {props.t("Plan Access")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}

            {Roles?.BokingsView == true || Roles?.accessAll == true ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bxs-alarm"></i>
                    <span>{props.t("Bookings")}</span>
                  </Link>
                  <ul className="sub-menu">
                    {Roles?.BokingsView == true || Roles?.accessAll == true ? (
                      <>
                        <li>
                          <Link to="/PendingBookings">
                            {props.t("Bookings List")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.CancelledBookings == true ||
                    Roles?.accessAll == true ? (
                      <>
                        <li>
                          <Link to="/CancelledBookings">
                            {props.t("Cancelled Bookings")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.PaymentsView == true || Roles?.accessAll == true ? (
                      <>
                        <li>
                          <Link to="/Payments">{props.t("Payments")}</Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}

            {Roles?.EnquiryView == true || Roles?.accessAll == true ? (
              <>
                <li>
                  <Link to="/Enquiry">
                    <i className="bx bxs-phone-incoming"></i>
                    <span>{props.t("Enquiry")}</span>
                  </Link>
                </li>
              </>
            ) : (
              ""
            )}

            {Roles?.CouponsView == true || Roles?.accessAll == true ? (
              <>
                <li>
                  <Link to="/Coupon">
                    <i className="bx bxs-badge-check"></i>
                    <span>{props.t("Coupons")}</span>
                  </Link>
                </li>
              </>
            ) : (
              ""
            )}

            {Roles?.SettingManagmentView == true || Roles?.accessAll == true ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bxs-widget"></i>
                    <span>{props.t("Settings")}</span>
                  </Link>
                  <ul className="sub-menu">
                    {Roles?.FaqsView == true || Roles?.accessAll == true ? (
                      <>
                        <li>
                          <Link to="/Faqs">{props.t("Faqs")}</Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}

                    {Roles?.SettingsView == true || Roles?.accessAll == true ? (
                      <>
                        <li>
                          <Link to="/ContactUs">{props.t("Contact Us")}</Link>
                        </li>
                        <li>
                          <Link to="/Terms">
                            {props.t("Terms & Conditions")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/PrivicyPolicy">
                            {props.t("Privacy Policy")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/RefundPolicy">
                            {props.t("Refund Policy")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
