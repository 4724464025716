import React, { useState, useEffect } from "react"
import { CardBody, Container, Row, Col, Card, Button, Table } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import { URLS } from "../../Url"
import axios from "axios"

function RecruitView() {
  const history = useHistory()

  useEffect(() => {
    GetOneActins()
  }, [])

  const [form, setform] = useState([])

  const Actinid = sessionStorage.getItem("Serviceidss")

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const GetOneActins = () => {
    const data = {
      id: Actinid,
    }

    var token = datas
    axios
      .post(URLS.GetoneService, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setform(res.data.service)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="ACTIN" breadcrumbItem="View Service" />
          <Row>
            <Col>
              <Button
                onClick={() => history.goBack()}
                className="mb-3  m-1 "
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i> Back
              </Button>
            </Col>
          </Row>

          <Card>
            <CardBody>
              <Row>
                <div>
                  <h5 className="mb-3 text-primary">Services Details:-</h5>
                  <div className="table-rep-plugin mt-4 table-responsive">
                    <Table hover className="table table-bordered mb-4">
                      <thead>
                        <tr className="text-center">
                          <th>Name </th>
                          <th>{form.name}</th>
                        </tr>
                        <tr className="text-center">
                          <th>Image</th>
                          <td>
                            <img
                              src={URLS.Base + form.video}
                              width="150px"
                            ></img>
                          </td>
                        </tr>
                        <tr className="text-center">
                          <th>Service banner Image</th>
                          <td>
                            <img
                              src={URLS.Base + form.bannerImage}
                              width="200px"
                              height="100px"
                            ></img>
                          </td>
                        </tr>
                        <tr className="text-center">
                          <th>Service icon</th>
                          <td>
                            <img
                              src={URLS.Base + form.icon}
                              width="100px"
                            ></img>
                          </td>
                        </tr>
                        <tr className="text-center">
                          <th>Description</th>
                          <td>{form.description}</td>
                        </tr>
                        <tr className="text-center">
                          <th>Seo Title </th>
                          <td>{form.seoTitle}</td>
                        </tr>
                        <tr className="text-center">
                          <th>Url Slug</th>
                          <td>{form.urlSlug}</td>
                        </tr>
                        <tr className="text-center">
                          <th>Seo Keywords</th>
                          <td>{form.seoKeywords}</td>
                        </tr>
                        <tr className="text-center">
                          <th>Seo Description </th>
                          <td>{form.seoDescription}</td>
                        </tr>
                      </thead>
                    </Table>
                  </div>
                </div>
              </Row>
              <h5 className="mb-3 mt-5 pt-5 text-primary">
                Services Large description:-
              </h5>
              <hr></hr>
              <Row>
                <Col lg={12}>
                  <div style={{ width: "100%" }}>
                    <div
                      className="mt-2"
                      dangerouslySetInnerHTML={{
                        __html: form.largeDescription,
                      }}
                    ></div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default RecruitView
