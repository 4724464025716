import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  Table,
  Form,
  Label,
  Modal,
} from "reactstrap"
import { ToastContainer, toast } from "react-toastify"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ReactPaginate from "react-paginate"
import { useHistory } from "react-router-dom"
import axios from "axios"
import { CSVLink } from "react-csv"
import { URLS } from "../../Url"

const Staff = () => {
  const history = useHistory()

  const [users, setusers] = useState([])

  const [userInCsv, setuserInCsv] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const Get = () => {
    var token = datas

    axios
      .post(
        URLS.GetPendingBookings,
        { fromDate: "", toDate: "", productId: "All", status: "All" },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setusers(res.data.orders)
        setuserInCsv(res.data.excellData)
      })
  }

  useEffect(() => {
    Get()
    getProducts()
  }, [])

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = users.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(users.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const [modal_small1, setmodal_small1] = useState(false)

  const [form2, setform2] = useState([])

  const handleChange2 = e => {
    let myUser = { ...form2 }
    myUser[e.target.name] = e.target.value
    setform2(myUser)
  }

  function tog_small1() {
    setmodal_small1(!modal_small1)
  }

  const getpopup1 = data => {
    setform2(data)
    tog_small1()
  }

  const handleSubmit2 = e => {
    e.preventDefault()
    Editstate1()
  }

  const Editstate1 = () => {
    var token = datas
    var formid = form2._id

    const dataArray = {
      status: form2.status,
    }

    axios
      .put(URLS.UpdatePendingBookings + "/" + formid, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            GetAgentFiliter()
            toast(res.data.message)
            setmodal_small1(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const Actinid = data => {
    localStorage.setItem("bookingids", data._id)
    // history.push("/ViewBookings")
    window.open("/ViewBookings", "_blank")
  }

  const [filter, setfilter] = useState(false)

  const [filters, setfilters] = useState({
    fromDate: "",
    toDate: "",
    productId: "",
    status: "",
  })

  const getfilter = e => {
    e.preventDefault()
    GetAgentFiliter()
  }

  const GetAgentFiliter = () => {
    var token = datas
    const data = {
      fromDate: filters.fromDate,
      toDate: filters.toDate,
      productId: filters.productId,
      status: filters.status,
    }
    axios
      .post(URLS.GetPendingBookings, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setusers(res.data.orders)
        setuserInCsv(res.data.excellData)
        hidefilter1()
      })
  }

  const hidefilter1 = () => setfilter(false)

  const hidefilter = () => {
    setfilter(false)
    Get()
    setfilters({
      fromDate: "",
      toDate: "",
      productId: "",
      status: "",
    })
  }

  const handleChangeflt = e => {
    let myUser = { ...filters }
    myUser[e.target.name] = e.target.value
    setfilters(myUser)
  }
  const csvReport = {
    filename: "Bookings Report",
    data: userInCsv,
  }

  const [Products, setProducts] = useState([])

  const getProducts = () => {
    var token = datas

    axios
      .post(
        URLS.GetProduct,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setProducts(res.data.products)
      })
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var Roles = data?.rolesAndPermission[0]

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Od Cards Admin" breadcrumbItem="Bookings" />

          <Row>
            {filter ? (
              <>
                <Card>
                  <CardBody>
                    <Form
                      onSubmit={e => {
                        getfilter(e)
                      }}
                    >
                      <Row>
                        <Col lg="3">
                          <div className="mb-3">
                            <Label for="basicpill-declaration-input10">
                              From Date
                            </Label>
                            <Input
                              type="date"
                              required
                              className="form-control"
                              id="basicpill-Declaration-input10"
                              onChange={e => {
                                handleChangeflt(e)
                              }}
                              name="fromDate"
                              value={filters.fromDate}
                            />
                          </div>
                        </Col>
                        <Col lg="3">
                          <div className="mb-3">
                            <Label for="basicpill-declaration-input10">
                              To Date
                            </Label>
                            <Input
                              type="date"
                              className="form-control"
                              id="basicpill-Declaration-input10"
                              onChange={e => {
                                handleChangeflt(e)
                              }}
                              name="toDate"
                              value={filters.toDate}
                            />
                          </div>
                        </Col>
                        <Col lg="3">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input3">
                              Product Wise
                            </Label>

                            <select
                              className="form-select"
                              value={filters.productId}
                              name="productId"
                              onChange={e => {
                                handleChangeflt(e)
                              }}
                            >
                              <option value=""> Select </option>
                              {Products.map((data, key) => {
                                return (
                                  <option key={key} value={data._id}>
                                    {data.name}
                                  </option>
                                )
                              })}
                            </select>
                          </div>
                        </Col>
                        <Col lg="3">
                          <div className="mb-3">
                            <Label>Status</Label>

                            <select
                              onChange={e => {
                                handleChangeflt(e)
                              }}
                              value={filters.status}
                              name="status"
                              className="form-select"
                            >
                              <option value="">Select Type</option>
                              <option value="pending">Pending</option>
                              <option value="confirmed">Confirmed</option>
                              <option value="shifted">Shifted</option>
                              <option value="ontheway">On the Way</option>
                              <option value="completed">Completed</option>
                            </select>
                          </div>
                        </Col>

                        <Col lg="3">
                          <div className="mt-4">
                            <Button type="submit" className="m-1" color="info">
                              <i className="fas fa-check-circle"></i> search
                            </Button>
                            <Button
                              onClick={hidefilter}
                              className="m-1"
                              color="danger"
                            >
                              <i className="fas fa-times-circle"></i> Clear
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </>
            ) : (
              ""
            )}
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <div className="col-md-12">
                      <div style={{ float: "right" }}>
                        <Row>
                          <Col>
                            <div style={{ float: "right" }}>
                              <CSVLink {...csvReport}>
                                <button
                                  className="btn btn-success me-2"
                                  type="submit"
                                >
                                  <i className="fas fa-file-excel"></i> Excel
                                </button>
                              </CSVLink>

                              <Button
                                className="m-1"
                                onClick={() => {
                                  setfilter(!filter)
                                }}
                                color="info"
                              >
                                <i className="fas fa-filter"></i> Filter
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="table-rep-plugin mt-4 table-responsive">
                        <Table hover bordered responsive>
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Booking Id</th>
                              <th>Booking Date</th>
                              <th>Booking Time</th>
                              <th>User Id</th>
                              <th>User Name</th>
                              <th>Phone</th>
                              <th>Email</th>
                              <th>Product Name</th>
                              <th>Product Quantity</th>
                              <th>Product Price</th>
                              <th>Total Price</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists.map((data, key) => (
                              <tr key={key}>
                                <th scope="row">
                                  {(pageNumber - 1) * 5 + key + 6}
                                </th>
                                <td>{data.orderId}</td>
                                <td>{data.date}</td>
                                <td>{data.time}</td>
                                <td>{data.userCode}</td>
                                <td>{data.userName}</td>
                                <td>{data.userPhone}</td>
                                <td>{data.userEmail}</td>
                                <td>
                                  {data.items.map((datas, i) => (
                                    <>
                                      <p>
                                        {i + 1}){datas.productName}
                                      </p>
                                    </>
                                  ))}
                                </td>
                                <td>
                                  {data.items.map(datas => (
                                    <>
                                      <p>{datas.priceValue}</p>
                                    </>
                                  ))}
                                </td>
                                <td>
                                  {data.items.map(datas => (
                                    <>
                                      <p>{datas.price}</p>
                                    </>
                                  ))}
                                </td>
                                <td>{data.amount}</td>
                                <td>{data.status}</td>
                                <td>
                                  {data.status == "completed" ||
                                  data.status == "cancelled" ? (
                                    <></>
                                  ) : (
                                    <>
                                      {Roles?.BokingsEdit == true ||
                                      Roles?.accessAll == true ? (
                                        <>
                                          <Button
                                            onClick={() => {
                                              getpopup1(data)
                                            }}
                                            size="sm"
                                            className="m-1"
                                            outline
                                            color="success"
                                          >
                                            <i
                                              style={{ fontSize: " 14px" }}
                                              className="bx bx-repeat"
                                            ></i>
                                          </Button>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  )}
                                  {Roles?.BokingsView == true ||
                                  Roles?.accessAll == true ? (
                                    <>
                                      <a
                                        href="#"
                                        // target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <Button
                                          className="mr-2"
                                          style={{
                                            padding: "6px",
                                            margin: "3px",
                                          }}
                                          onClick={() => {
                                            Actinid(data)
                                          }}
                                          color="info"
                                          outline
                                        >
                                          <i className="fas fa-eye"></i>
                                        </Button>
                                      </a>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <Col sm="12">
                          <div
                            className="d-flex mt-3 mb-1"
                            style={{ float: "right" }}
                          >
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              pageCount={pageCount}
                              onPageChange={changePage}
                              containerClassName={"pagination"}
                              previousLinkClassName={"previousBttn"}
                              nextLinkClassName={"nextBttn"}
                              disabledClassName={"disabled"}
                              activeClassName={"active"}
                              total={lists.length}
                            />
                          </div>
                        </Col>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>

        <Modal
          size="md"
          isOpen={modal_small1}
          toggle={() => {
            tog_small1()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Status Update
            </h5>
            <button
              onClick={() => {
                setmodal_small1(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                handleSubmit2(e)
              }}
            >
              <div className="mb-3">
                <Label>Status</Label>
                <span className="text-danger">*</span>
                <select
                  onChange={e => {
                    handleChange2(e)
                  }}
                  value={form2.status}
                  name="status"
                  className="form-select"
                  required
                >
                  <option value="">Select Type</option>
                  <option value="confirmed">Confirmed</option>
                  <option value="processed">Processed</option>
                  <option value="shifted">Shifted</option>
                  <option value="ontheway">On the Way</option>
                  <option value="completed">Completed</option>
                </select>
              </div>

              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small1(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="primary" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default Staff
