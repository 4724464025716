import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Modal,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { URLS } from "../../Url"

const Banner = () => {
  const [modal_small, setmodal_small] = useState(false)

  const [inputList, setInputList] = useState([{ benefits: "" }])

  const [inputList1, setInputList1] = useState([])

  const [show, setshow] = useState(false)

  const [banner, setbanner] = useState([])
  const [form, setform] = useState([])
  const [form1, setform1] = useState([])

  function tog_small() {
    setmodal_small(!modal_small)
  }

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }
  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  useEffect(() => {
    GetAllBanners()
    GetAmcs()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = banner.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(banner.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const handleSubmit = e => {
    e.preventDefault()
    AddBanner()
  }

  const AddBanner = () => {
    var token = datas

    const dataArray = {
      title: form.title,
      description: form.description,
      price: form.price,
      offerPrice: form.offerPrice,
      amcserviceId: form.amcserviceId,
      benefits: inputList,
      type: form.type,
    }

    axios
      .post(URLS.AddServicePlans, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            setshow(false)
            GetAllBanners()
            clearForm()
            setInputList("")
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const EditBanner = () => {
    var token = datas
    var formid = form1._id
    const dataArray = {
      title: form1.title,
      description: form1.description,
      price: form1.price,
      offerPrice: form1.offerPrice,
      benefits: inputList1,
      amcserviceId: form1.amcserviceId,
      type: form1.type,
    }

    axios
      .put(URLS.UpdateServicePlans + formid, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetAllBanners()
            setmodal_small(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const DeleteBanner = data => {
    var token = datas
    var remid = data._id
    axios
      .delete(URLS.DeleteServicePlans + remid, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetAllBanners()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      DeleteBanner(data)
    }
  }

  const handleSubmit1 = e => {
    e.preventDefault()
    EditBanner()
  }

  const GetAllBanners = () => {
    var token = datas
    axios
      .post(
        URLS.GetServicePlans,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setbanner(res.data.serviceplans)
        setIsLoading(false)
      })
  }

  const clearForm = () => {
    setform({
      title: "",
      description: "",
      price: "",
      offerPrice: "",
      amcserviceId: "",
      type: "",
    })
  }

  const getpopup = data => {
    setform1(data)
    setInputList1(data.benefits)
    tog_small()
  }

  const [search, setsearch] = useState([])

  const searchAll = e => {
    let myUser = { ...search }
    myUser[e.target.name] = e.target.value
    setsearch(myUser)

    var token = datas
    axios
      .post(
        URLS.GetServicePlansSearch + `${e.target.value}`,
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setbanner(res.data.serviceplans)
      })
  }
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const [Amc, setAmc] = useState([])

  const GetAmcs = () => {
    var token = datas
    axios
      .post(
        URLS.GetAmcServices,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setAmc(res.data.amcservices)
      })
  }

  const handleInputChange = (e, index) => {
    const { name, value } = e.target
    const list = [...inputList]
    list[index][name] = value
    setInputList(list)
  }

  const handleRemoveClick = index => {
    const list = [...inputList]
    list.splice(index, 1)
    setInputList(list)
  }

  const handleAddClick = () => {
    setInputList([...inputList, { benefits: "" }])
  }

  const handleInputChange1 = (e, index) => {
    const { name, value } = e.target
    const list = [...inputList1]
    list[index][name] = value
    setInputList1(list)
  }

  const handleRemoveClick1 = index => {
    const list = [...inputList1]
    list.splice(index, 1)
    setInputList1(list)
  }

  const handleAddClick1 = () => {
    setInputList1([...inputList1, { benefits: "" }])
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Od Cards Admin"
            breadcrumbItem="Service Plans"
          />
          <Row>
            {show == true ? (
              <Col md={12}>
                <Card>
                  <CardHeader className="bg-white">
                    <CardTitle>Add Service Plans</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form
                      onSubmit={e => {
                        handleSubmit(e)
                      }}
                    >
                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Title <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="basicpill-firstname-input1"
                              placeholder="Enter Title"
                              required
                              name="title"
                              value={form.title}
                              onChange={e => {
                                handleChange(e)
                              }}
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Price <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="basicpill-firstname-input1"
                              placeholder="Enter Price"
                              required
                              name="price"
                              value={form.price}
                              onChange={e => {
                                handleChange(e)
                              }}
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Offer Price <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="basicpill-firstname-input1"
                              placeholder="Enter  Offer Price"
                              required
                              name="offerPrice"
                              value={form.offerPrice}
                              onChange={e => {
                                handleChange(e)
                              }}
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Amc Service <span className="text-danger">*</span>
                            </Label>
                            <select
                              value={form.amcserviceId}
                              name="amcserviceId"
                              onChange={e => {
                                handleChange(e)
                              }}
                              required
                              className="form-select"
                            >
                              <option value="">Select</option>
                              {Amc.map((data, key) => {
                                return (
                                  <option key={key} value={data._id}>
                                    {data.title}
                                  </option>
                                )
                              })}
                            </select>
                          </div>
                        </Col>

                        <Col md={6}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Type <span className="text-danger">*</span>
                            </Label>
                            <select
                              value={form.type}
                              name="type"
                              required
                              onChange={e => {
                                handleChange(e)
                              }}
                              className="form-select"
                            >
                              <option value="">Select</option>
                              <option value="splitac">Split Ac</option>
                              <option value="windowac">Window Ac</option>
                            </select>
                          </div>
                        </Col>

                        <Col lg="6">
                          <div>
                            <Label>Benefits</Label>
                            <Row>
                              {inputList.map((x, i) => {
                                return (
                                  <>
                                    <Row>
                                      <div key={i} className="box row">
                                        <Col md="8" sm="12" className="mb-3">
                                          <Input
                                            type="text"
                                            required
                                            name="benefits"
                                            placeholder="Enter Benefits"
                                            value={x.benefits}
                                            onChange={e =>
                                              handleInputChange(e, i)
                                            }
                                          />
                                        </Col>

                                        <Col sm="2" md="3">
                                          <div className="btn-box">
                                            {inputList.length !== 1 && (
                                              <button
                                                className="mr10 btn btn-outline-danger btn-sm m-1"
                                                type="button"
                                                onClick={() =>
                                                  handleRemoveClick(i)
                                                }
                                              >
                                                Remove
                                                <i className="bx bx-x-circle"></i>
                                              </button>
                                            )}
                                            {inputList.length - 1 === i && (
                                              <button
                                                className="btn btn-sm btn-outline-info m-1"
                                                onClick={handleAddClick}
                                              >
                                                Add
                                                <i className="bx bx-plus-circle"></i>
                                              </button>
                                            )}
                                          </div>
                                        </Col>
                                      </div>
                                    </Row>
                                  </>
                                )
                              })}
                            </Row>
                          </div>
                        </Col>

                        <Col md={12}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Description <span className="text-danger">*</span>
                            </Label>
                            <textarea
                              type="text"
                              rows="3"
                              className="form-control "
                              id="basicpill-firstname-input1"
                              placeholder="Enter Description"
                              required
                              value={form.description}
                              name="description"
                              onChange={e => {
                                handleChange(e)
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <div style={{ float: "right" }}>
                        <Button color="primary" type="submit">
                          Submit <i className="fas fa-check-circle"></i>
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            ) : (
              ""
            )}

            <Col md={12}>
              <Card>
                <CardHeader className="bg-white">
                  <CardTitle>Service Plans List</CardTitle>
                </CardHeader>

                <CardBody>
                  <div>
                    <div className="table-responsive">
                      <div style={{ float: "right" }}></div>

                      <Row>
                        <Col>
                          <Button
                            onClick={() => {
                              setshow(!show)
                            }}
                            color="primary"
                          >
                            Add Service Plans
                            <i className="bx bx-user-plus"></i>
                          </Button>
                        </Col>
                        <Col>
                          <div style={{ float: "right" }}>
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Search.."
                              value={search.search}
                              onChange={searchAll}
                              name="search"
                            />
                          </div>
                        </Col>
                      </Row>

                      <Table className="table table-bordered mb-4 mt-5">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>ServiceName</th>
                            <th>Title</th>
                            <th>Type</th>
                            <th>Price </th>
                            <th>OfferPrice </th>
                            <th>Benefits </th>
                            <th>Description </th>
                            <th style={{ width: "100px" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {lists.map((data, key) => (
                            <tr key={key}>
                              <th>{(pageNumber - 1) * 5 + key + 6}</th>
                              <td>{data.amcserviceTitle}</td>
                              <td>{data.title}</td>
                              <td>{data.type=="windowac" ? <>Window Ac</>:<></>} {data.type=="splitac" ? <>Split Ac</>:<></>}	</td>
                              <td>{data.price}</td>
                              <td>{data.offerPrice}</td>
                              <td>
                                {data.benefits.map((datas, keys) => (
                                  <>
                                    <p key={keys}>
                                      {" "}
                                      {keys + 1}). {datas.benefits}
                                    </p>
                                  </>
                                ))}
                              </td>
                              <td>{data.description}</td>
                              <td>
                                <Button
                                  onClick={() => {
                                    getpopup(data)
                                  }}
                                  className="mr-2"
                                  style={{
                                    padding: "6px",
                                    margin: "3px",
                                  }}
                                  color="success"
                                  outline
                                >
                                  <i className="bx bx-edit "></i>
                                </Button>
                                <Button
                                  onClick={() => {
                                    manageDelete(data)
                                  }}
                                  style={{
                                    padding: "6px",
                                    margin: "3px",
                                  }}
                                  color="danger"
                                  outline
                                >
                                  <i className="bx bx-trash"></i>
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>

                      <div className="mt-3" style={{ float: "right" }}>
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          pageCount={pageCount}
                          onPageChange={changePage}
                          containerClassName={"pagination"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"disabled"}
                          activeClassName={"active"}
                          total={lists.length}
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal
          size="xl"
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Edit Service Plans
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                handleSubmit1(e)
              }}
            >
              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Title <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Title"
                      required
                      name="title"
                      value={form1.title}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Price <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Price"
                      required
                      name="price"
                      value={form1.price}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Offer Price <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter  Offer Price"
                      required
                      name="offerPrice"
                      value={form1.offerPrice}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Amc Service <span className="text-danger">*</span>
                    </Label>
                    <select
                      value={form1.amcserviceId}
                      name="amcserviceId"
                      onChange={e => {
                        handleChange1(e)
                      }}
                      className="form-select"
                    >
                      <option value="">Select</option>
                      {Amc.map((data, key) => {
                        return (
                          <option key={key} value={data._id}>
                            {data.title}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Type <span className="text-danger">*</span>
                    </Label>
                    <select
                      value={form1.type}
                      name="type"
                      required
                      onChange={e => {
                        handleChange1(e)
                      }}
                      className="form-select"
                    >
                      <option value="">Select</option>
                      <option value="splitac">Split Ac</option>
                      <option value="windowac">Window Ac</option>
                    </select>
                  </div>
                </Col>
                <Col md={6}>
                  <div>
                    <Label>Benefits</Label>
                    <Row>
                      {inputList1.map((x, i) => {
                        return (
                          <>
                            <Row>
                              <div key={i} className="box row">
                                <Col md="8" sm="12" className="mb-3">
                                  <Input
                                    type="text"
                                    required
                                    name="benefits"
                                    placeholder="Enter Benefits"
                                    value={x.benefits}
                                    onChange={e => handleInputChange1(e, i)}
                                  />
                                </Col>

                                <Col sm="2" md="3">
                                  <div className="btn-box">
                                    {inputList1.length !== 1 && (
                                      <button
                                        className="mr10 btn btn-outline-danger btn-sm m-1"
                                        type="button"
                                        onClick={() => handleRemoveClick1(i)}
                                      >
                                        Remove
                                        <i className="bx bx-x-circle"></i>
                                      </button>
                                    )}
                                    {inputList1.length - 1 === i && (
                                      <button
                                        className="btn btn-sm btn-outline-info m-1"
                                        onClick={handleAddClick1}
                                      >
                                        Add
                                        <i className="bx bx-plus-circle"></i>
                                      </button>
                                    )}
                                  </div>
                                </Col>
                              </div>
                            </Row>
                          </>
                        )
                      })}
                    </Row>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Description <span className="text-danger">*</span>
                    </Label>
                    <textarea
                      type="text"
                      rows="3"
                      className="form-control "
                      id="basicpill-firstname-input1"
                      placeholder="Enter Description"
                      required
                      value={form1.description}
                      name="description"
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="primary" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default Banner
