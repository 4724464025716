import React, { useEffect, useState } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  Table,
  Label,
  Input,
  CardTitle,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import { URLS } from "../../Url"
import axios from "axios"

function DigitalBrochure() {
  const [files, setFiles] = useState([])

  const [files1, setFiles1] = useState([])

  const [show, setshow] = useState(false)

  const changeHandler = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (
      type == "jpg" ||
      type == "jpeg" ||
      type == "png" ||
      type == "JPG" ||
      type == "JPEG" ||
      type == "PNG" ||
      type == "JPEG" ||
      type == "WEBP" ||
      type == "webp"
    ) {
      setFiles(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }

  const changeHandler1 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (
      type == "jpg" ||
      type == "jpeg" ||
      type == "png" ||
      type == "JPG" ||
      type == "JPEG" ||
      type == "PNG" ||
      type == "JPEG" ||
      type == "WEBP" ||
      type == "webp"
    ) {
      setFiles1(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }

  const [form, setform] = useState([])

  const [forms, setforms] = useState([])

  const handlechange = e => {
    const myform = { ...forms }
    myform[e.target.name] = e.target.value
    setforms(myform)
  }

  useEffect(() => {
    GetAllBroucher()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const GetAllBroucher = () => {
    var token = datas

    axios
      .post(
        URLS.GetHomeContent,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setform(res.data.homeslider)
        setforms(res.data.homeslider)
      })
  }

  function tog_small() {
    setshow(!show)
  }

  const getpopup1 = () => {
    tog_small()
  }

  const submibooking = e => {
    e.preventDefault()
    changstatus()
  }

  const changstatus = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("title", forms.title)
    dataArray.append("description", forms.description)
    // dataArray.append("deliveryCount", forms.deliveryCount)

    // for (let i = 0; i < files.length; i++) {
    //   dataArray.append("image1", files[i])
    // }
    // for (let i = 0; i < files1.length; i++) {
    //   dataArray.append("image2", files1[i])
    // }

    axios
      .put(URLS.UpdatHomeContent, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            setshow(false)
            GetAllBroucher()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var Roles = data?.rolesAndPermission[0]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Od Cards Admin" breadcrumbItem="Home Content" />

          {show == true ? (
            <Col md={12}>
              <Card>
                <CardHeader className="bg-white">
                  <CardTitle>Edit Home Content</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      submibooking(e)
                    }}
                  >
                    <Row>
                      <Col md={12}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Title <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Title"
                            required
                            value={forms.title}
                            name="title"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      {/* <Col md={6}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Delivery Count{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Delivery Count"
                            required
                            value={forms.deliveryCount}
                            name="deliveryCount"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col> */}
                      {/* <Col md={6}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Image <span className="text-danger">* 400*600</span>
                          </Label>
                          <Input
                            type="file"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            name="image"
                            value={files.image}
                            onChange={changeHandler}
                          />
                        </div>
                      </Col> */}
                      {/* <Col md={6}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Small Image{" "}
                            <span className="text-danger">* 400*600</span>
                          </Label>
                          <Input
                            type="file"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            name="image"
                            value={files1.image}
                            onChange={changeHandler1}
                          />
                        </div>
                      </Col> */}

                      <Col md={12}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Description <span className="text-danger">*</span>
                          </Label>
                          <textarea
                            type="text"
                            rows="6"
                            className="form-control "
                            id="basicpill-firstname-input1"
                            placeholder="Enter Service Description"
                            required
                            value={forms.description}
                            name="description"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <div style={{ float: "right" }}>
                      <Button color="primary" type="submit">
                        Submit <i className="fas fa-check-circle"></i>
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          ) : (
            ""
          )}

          <Row>
            <Col md={12}>
              <Card>
                <CardHeader className="bg-white">
                  <Row>
                    {Roles?.HomeContentEdit == true || Roles?.accessAll == true ? (
                      <>
                        <Col>
                          <div style={{ float: "right" }}>
                            <Button
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Edit Booking"
                              onClick={() => {
                                getpopup1()
                              }}
                              className="mr-5 mb-1 m-1 mt-3"
                              color="success"
                              outline
                            >
                              <i className="bx bx-edit text-dark "></i>
                              <span>Edit Home Content</span>
                            </Button>
                          </div>
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                  </Row>
                </CardHeader>

                <CardBody>
                  <Row>
                    <div>
                      <div className="table-rep-plugin mt-4 table-responsive">
                        <Table hover className="table table-bordered mb-4">
                          <thead>
                            <tr className="text-center">
                              <th>Title </th>
                              <td>{form.title}</td>
                            </tr>

                            {/* <tr className="text-center">
                              <th>Delivery Count </th>
                              <td>{form.deliveryCount}</td>
                            </tr>

                            <tr className="text-center">
                              <th >Image</th>
                              <td>
                                <img
                                  src={URLS.Base + form.image1}
                                  width="150px"
                                ></img>
                              </td>
                            </tr>

                            <tr className="text-center">
                              <th>Image</th>
                              <td>
                                <img
                                  src={URLS.Base + form.image2}
                                  width="150px"
                                ></img>
                              </td>
                            </tr> */}

                            <tr className="text-center">
                              <th style={{paddingBottom:"80px"}}>Description</th>
                              <td>{form.description}</td>
                            </tr>
                          </thead>
                        </Table>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default DigitalBrochure
